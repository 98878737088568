import {
	BlogHero,
	BlogPosts,
	ContentWithLink,
	Spacer,
	Cta,
	Pagination,
	Button,
} from 'components';
import emptyImage from 'images/blog/empty-blog-post-image.jpg';
import { graphql, navigate, PageProps } from 'gatsby';
import { BlogProps } from './blog-template';
import '../styles/templates/blog-category-template-styles.scss';

export default function BlogTemplate(props: PageProps<BlogProps>) {
	return (
		<>
			<BlogHero
				title={`${props.pageContext.category}.`}
				subtitle="Read recent articles from our web development blog"
				afterSubtitle={
					<Button to="/blog" color="purple">
						Back to blog page
					</Button>
				}
				isCategory={true}
			/>
			<Spacer height={80} responsive={{ md: 60, sm: 40 }} />
			<BlogPosts props={props} />
			<Pagination
				total={props.pageContext.frontmatter.categoriesPagesCount}
				onChange={(page) => {
					if (page === 1) {
						navigate(
							`/blog/category/${props.pageContext.category.toLowerCase()}`
						);
					} else {
						navigate(
							`/blog/category/${props.pageContext.category.toLowerCase()}/page/${page}`
						);
					}
				}}
				current={props.pageContext.frontmatter.currentPage}
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			<Cta
				title="Take partnership to the next level"
				subtitle="Some clients stay with us for over 4 years. With our experienced team,  we’re ready to assist you with full-cycle development"
				buttonText="Get in touch"
				to="/contact"
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			<ContentWithLink
				headline="No **Department** work as **we do**"
				content="Behind the Deparment of Web Software House stands our dream team of experienced frontend/backend developers, product designer and marketing specialist. We design, develop and maintain software built on top on WordPress and Laravel."
				image={{
					src: emptyImage,
					title: 'about',
				}}
				button={{
					children: 'Get in touch',
					to: '/contact',
				}}
				imagePosition="right"
			/>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
		</>
	);
}

export const pageQuery = graphql`
	query BLOG_CATEGORY_POSTS_PAGE(
		$skip: Int!
		$limit: Int!
		$category: String
	) {
		allMdx(
			filter: {
				frontmatter: {
					slug: { regex: "/^/blog//i" }
					category: { eq: $category }
				}
			}
			sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					id
					body
					frontmatter {
						slug
						title
						author
						date
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
						image_title
					}
				}
			}
		}
	}
`;
